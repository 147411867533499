import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';
import * as Reducers from "../flex/Reducers";
import * as Actions from "../flex/Actions";
import * as endpoints from "../apis/endpoints";
import { apiAccess } from "../apis/AxiosWrapper"
import {
    useHistory,
} from "react-router-dom";




export default function AuthEffectComponent() {

    const loginConnection = useSelector(state => state.loginConnection)
    const logoutConnection = useSelector(state => state.logoutConnection)
    const dispatch = useDispatch();

    useEffect(() => {
        if(loginConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.data.session(true));
        }
        if(loginConnection.meta.status === Actions.statusEnum.FAILURE || loginConnection.meta.status === Actions.statusEnum.ERROR){
            dispatch(Actions.data.session(false));
        }
    }, [loginConnection]);


    useEffect(() => {
        if(logoutConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.data.session(false));
        }
        if(logoutConnection.meta.status === Actions.statusEnum.FAILURE || loginConnection.meta.status === Actions.statusEnum.ERROR){
            dispatch(Actions.data.session(true));
        }
    },[logoutConnection])


    return null
}