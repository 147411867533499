import React, {useReducer, useContext, useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import Dashboard from "../components/menu/Dashboard";
import Login from "../components/login/Login";
import {useDispatch, useSelector} from "react-redux";
import LoginInterface from "../components/login/LoginInterface";
import AuthEffectComponent from "./AuthEffectComponent";
import * as Actions from "../flex/Actions";
import Cancels from "../components/cancels/Cancels";
import Signage from "../components/signage/Signage";
import SignageHeader from "../components/signage/SignageHeader";
import MobileCancels from "../components/cancels/mobile/MobileCancels";

// This example has 3 pages: a public page, a protected
// page, and a login screen. In order to see the protected
// page, you must first login. Pretty standard stuff.
//
// First, visit the public page. Then, visit the protected
// page. You're not yet logged in, so you are redirected
// to the login page. After you login, you are redirected
// back to the protected page.
//
// Notice the URL change each time. If you click the back
// button at this point, would you expect to go back to the
// login page? No! You're already logged in. Try it out,
// and you'll see you go back to the page you visited
// just *before* logging in, the public page.

// createContextを使用する
const context = React.createContext();

interface IState{
    count: number;
    user: {
        name: string;
    };
}
const initialState: IState = {
    count: 0,
    user: {name: 'araki'}
};

const reducer = (state: IState, action: any) => {
    switch (action.type) {
        case 'INCREMENT':
            return {
                ...state,
                count: state.count + 1
            };
        default:
            return state;
    }
};

// contextへ stateとdispatchをバインドする
const Provider = (props: { children: any }) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    return <context.Provider value={{state, dispatch}}>{children}</context.Provider>;
};

export default function Auth() {

    const dispatch = useDispatch();
    //dispatch(Actions.http.connection.authentication.login())

    return (
        <Router>
                <div>
                    <Switch>
                        <Route exact path="/cancels">
                            <Cancels />
                        </Route>
                        <Route exact path={"/cancels/:id"}>
                            <Cancels />
                        </Route>
                        <Route exact path={"/signage"}>
                            <Signage />
                        </Route>
                        <Route exact path={"/signage/:categoryNum"}>
                            <Signage />
                        </Route>
                        <Route exact path={"/mobile"}>
                            <MobileCancels />
                        </Route>
                        <Route exact path={"/mobile/:id"}>
                            <MobileCancels />
                        </Route>
                        <Route>
                            <Redirect
                                to={{
                                    pathname: "/signage"
                                }}
                            />
                        </Route>
                    </Switch>
                    <AuthEffectComponent/>
                </div>
        </Router>
    );
}

function PrivateRoute({children, ...rest}) {
    const session = useSelector(state => state.session);
    const loginConnection = useSelector(state => state.loginConnection);

    return (
        loginConnection.meta.fetch || !session.payload.is_checked?
            <div>private fetch or is_checked</div>
            :
            <Route
                {...rest}
                render={({location}) =>
                        session.payload.is_authenticated? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/signage",
                                state: {from: location}
                            }}
                        />
                    )
                }
            />
    );
}

//未認証時のページ
function PublicPage({children, ...rest}) {
    const session = useSelector(state => state.session);
    const loginConnection = useSelector(state => state.loginConnection);

    return (
        loginConnection.meta.fetch || !session.payload.is_checked ?
            <div>public fetch or is_checked</div>
            :
            <Route
                {...rest}
                render={({location}) =>
                    session.payload.is_authenticated? (
                        <Redirect
                            to={{
                                pathname: "/home",
                                state: {from: location}
                            }}
                        />
                    ) : (
                        <Cancels />

                    )
                }
            />
    );
}

//認証時のページ
function ProtectedPage() {
    return <h3>Protected</h3>;
}

