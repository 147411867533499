import * as Actions from "./Actions";
import { apiAccess } from "../apis/AxiosWrapper"

export const connection = store => next => action => {
    next(action);
    // POSTリクエスト
    if (action.type.match("^CONNECTION") && action.meta.fetch) {
        if (action.payload.method !== null && action.payload.method === "post") {
            let query = {};
            if (action.payload.hasOwnProperty("query")) {
                query = action.payload.query;
            }
            apiAccess.post(action.payload.api,action.payload.data)
                .then((response) => {
                    console.log(response)
                    next(Object.assign(action, {
                            payload: {
                                ...response.data
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS
                            }
                        })
                    );
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.error(error.request);
                        next(Object.assign(action, {
                                payload: {
                                    ...error.request
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error', error.message);
                        next(Object.assign(action, {
                            payload: {
                                ...error.message
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.ERROR
                            }
                        }))
                    }
                });

        };
        // GETリクエスト
        if (action.payload.method !== null && action.payload.method === 'get') {
            let query = {};
            if (action.payload.hasOwnProperty("query")) {
                query = action.payload.query;
            }
            apiAccess.get(action.payload.api,action.payload.data)
                .then((response) => {
                    next(Object.assign(action, {
                            payload: {
                                ...response.data
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.SUCCESS
                            }
                        })
                    );
                })
                .catch((error) => {
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                        if(error.response.status === 412){
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.SUCCESS
                                    }
                                })
                            );
                        }else{
                            next(Object.assign(action, {
                                    payload: {
                                        ...error.response.data
                                    },
                                    meta: {
                                        fetch: false,
                                        status: Actions.statusEnum.FAILURE
                                    }
                                })
                            );
                        }


                    } else if (error.request) {
                        // The request was made but no response was received
                        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                        // http.ClientRequest in node.js
                        console.error(error.request);
                        next(Object.assign(action, {
                                payload: {
                                    ...error.request
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.error('Error', error.message);
                        next(Object.assign(action, {
                            payload: {
                                ...error.message
                            },
                            meta: {
                                fetch: false,
                                status: Actions.statusEnum.ERROR
                            }
                        }))
                    }
                })

        }
    }
};