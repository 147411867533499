import React, {useEffect, useState} from 'react';
import moment from "moment";
import * as Helper from "../../constants/Helper";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";

export default function SignageHeader(props) {

    let {categoryNum} = useParams();
    const category = useSelector(state => state.category);
    const [day, setDay] = useState(moment().format('MM月DD日（ddd)'));
    const [time, setTime] = useState(moment().format('HH:mm'))
    const [categoryTitle,setCategoryTitle] = useState("");

    setInterval(
        ()=>{
            setDay(moment().format('MM月DD日（ddd)'));
            setTime(moment().format('HH:mm'));
        },
        1000
    );

    useEffect(()=>{

        const currentCategory = category.payload.find((element) => element.CATEGORY_ID === categoryNum)
        setCategoryTitle(currentCategory ? currentCategory.CATEGORY_NAME : "");
    },[categoryNum,category])

    return (
        <>
            <header>
                <div id={"header-body"}>
                    <div id="header-logo">
                        <img id="header-logo-img" src={`${process.env.PUBLIC_URL}/skin/${Helper.generateTenantKey(window.location.host).key}/header_logo.png`} alt="Ist-Reserve"/>
                    </div>
                    <div id={"header-title"} >
                        <h1>{categoryTitle}</h1>
                    </div>
                    <div id="header-action">
                    </div>
                </div>
                <div id="header-time">
                    <div id="header-time-day">{day}</div>
                    <div id="header-time-hour">{time}</div>
                </div>
            </header>
        </>
    );
}
