import React, {useEffect, useState} from 'react';
import * as Actions from "../../../flex/Actions";
import {useDispatch, useSelector} from "react-redux";
import {Switch, useLocation, useParams} from "react-router-dom";
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {useTransition, animated} from 'react-spring'
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import IntervalTimer from 'react-interval-timer';
import moment from "moment";
import "moment/locale/ja";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import * as Helper from "../../../constants/Helper";
import CancelsNoContent from "../CancelsNoContent";
import MobileCancelsNoContent from "./MobileCancelNoContent";

moment.locale('ja')

const useStyles = makeStyles(theme => ({
    table: {
        height: "100%",
        width: "100%"
    },
    progressWrapper: {
        position: "absolute",
        height: "calc(100vh - 100px)",
        width: "100%"
    },
    progress: {
        position: "absolute",
        right: 20,
        bottom: 20,
        height: 100,
        width: 100,
        zIndex: 111111
    },
    progressText: {
        position: "absolute",
        right: 20,
        bottom: 20,
        height: 100,
        width: 100,
        zIndex: 111111,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.background.paper
    },
    inline: {
        display: 'inline',
    },
    listItemWrapper: {
        width: "100%",

    },
    wrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}));
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        fontSize: 10,
    },
    body: {
        fontSize: 10,
    },
}))(TableRow);
const generateArray = (id, returnObject) => {
    //id（現段階のパラメータ)
    /*
        1: 白山休講情報
        2: 白山休講情報（文学部第1部）
        3: 白山休講情報（経済学部第1部)
        4: 白山休講情報（経営学部第1部)
        5: 白山休講情報（法学部第1部)
        6: 白山休講情報（社会学部第1部)
        7: 白山休講情報（国際地位学科・グローバル・イノベーション学科)
        8: 白山休講情報（国際観光学科）
        9: 朝霞休講情報
        10:川越休講情報
        11:板倉休講情報
     */

    if (id) {
        return returnObject.hasOwnProperty(id) ? returnObject[id] : []
    } else {
        let generatedArray = returnObject.hasOwnProperty(1) ? returnObject[1].concat() : [];
        let generatedArray2 = returnObject.hasOwnProperty(2) ? returnObject[2].concat() : [];
        let generatedArray3 = returnObject.hasOwnProperty(3) ? returnObject[3].concat() : [];
        let generatedArray4 = returnObject.hasOwnProperty(4) ? returnObject[4].concat() : [];
        let generatedArray5 = returnObject.hasOwnProperty(5) ? returnObject[5].concat() : [];
        let generatedArray6 = returnObject.hasOwnProperty(6) ? returnObject[6].concat() : [];
        let generatedArray7 = returnObject.hasOwnProperty(7) ? returnObject[7].concat() : [];
        let generatedArray8 = returnObject.hasOwnProperty(8) ? returnObject[8].concat() : [];
        let generatedArray9 = returnObject.hasOwnProperty(9) ? returnObject[9].concat() : [];
        let generatedArray10 = returnObject.hasOwnProperty(10) ? returnObject[10].concat() : [];
        let generatedArray11 = returnObject.hasOwnProperty(11) ? returnObject[11].concat() : [];
        generatedArray = generatedArray.concat(generatedArray2, generatedArray3, generatedArray4, generatedArray5, generatedArray6, generatedArray7, generatedArray8, generatedArray9, generatedArray10, generatedArray11);
        return generatedArray;
    }
};


const needScroll = (array) => {
    return array.length > 9
};


export default function CancelsBody() {
    const classes = useStyles();
    const kyuko = useSelector(state => state.kyuko);
    const dispatch = useDispatch();
    let {id} = useParams();
    const [count, addCount] = useState(0);
    const [kyukoArray, setKyukoArray] = useState([]);
    const [isOdd, setCurrentNth] = useState(true);

    useEffect(() => {

        setKyukoArray(generateArray(id, kyuko.payload))
    }, [kyuko]);


    useEffect(() => {
        if (kyukoArray.length !== 0) {
            const firstObject = kyukoArray[0];
            kyukoArray.shift();
            kyukoArray.push(firstObject);
            setKyukoArray(kyukoArray);
        }
        ;
        setCurrentNth(!isOdd)
    }, [count]);


    const sortArray = () => {
        //サイズ固定で 9個以上存在する場合のみloopさせる
        if (needScroll(kyukoArray)) {
            addCount(count + 1);
        }
    };

    let height = 0;
    const heightSize = 100;
    const transition = useTransition(
        kyukoArray.map((data) => {
            console.log(height)
            return {...data, y: (height += heightSize) - heightSize, height: heightSize}
        }),
        d => d.kp_cancel_id,
        {
            from: {height: 0, opacity: 0},
            leave: {height: 0, opacity: 0},
            enter: ({y, height}) => ({y, height, opacity: 1}),
            update: ({y, height}) => ({y, height})
        }
    );

    const calcPercent = () => {
        console.log(kyukoArray.length / (count % kyukoArray.length + 1) * 100)
        return ((count % kyukoArray.length + 1) / kyukoArray.length) * 100;
    };

    return (
        <>
            <div id={"mobile"}>
                <List className={classes.root}>
                    {kyukoArray.length === 0 ?
                        <MobileCancelsNoContent />
                        :
                        kyukoArray.map((item, index) => {
                        const stringDate = moment(item.execute_date).format("MM/DD(dd)");
                        return (
                            <div key={index}>
                                <ListItem alignItems="flex-start">
                                    <div className={classes.listItemWrapper}>
                                        <Typography
                                            component="span"
                                            variant="subtitle2"
                                            className={classes.right}
                                            color="textSecondary">
                                            {`${Helper.generateCancelCategory(item.kp_cancel_category_id)} - ${item.department}`}
                                        </Typography>
                                        <ListItemText
                                            primary={item.class_name}
                                        />
                                        <React.Fragment>
                                            <div className={classes.wrapper}>
                                                <Typography
                                                    component="span"
                                                    variant="subtitle2"
                                                    className={classes.right}
                                                    color="textPrimary">
                                                    {item.teacher_name}
                                                </Typography>
                                                <div>
                                                    <Typography
                                                        component="span"
                                                        variant="subtitle1"
                                                        className={classes.inline}
                                                        color="textPrimary"
                                                    >{
                                                        `${item.timetable}`}
                                                    </Typography>
                                                    <Typography
                                                        component="span"
                                                        variant="subtitle1"
                                                        className={classes.inline}
                                                        color="textPrimary"
                                                    >
                                                        {` - ${stringDate}`}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    </div>
                                </ListItem>
                                <Divider/>

                            </div>

                        )
                    })}


                </List>


                {/*
                <div className={`mobile-list-header`}>
                    <div className={"number cell"}>No</div>
                    <div className={"date cell"}>休講日</div>
                    <div className={"timetable cell"}>時間割</div>
                    <div className={"teacher cell"}>教員</div>
                    <div className={"name cell"}>科目名</div>
                    <div className={"department cell"}>学部</div>
                </div>
                <ul className={`mobile-signage-list ${isOdd ? "odd" : "even"}`}>
                    {kyukoArray.map((item,index) => {
                        const stringDate = moment(item.execute_date).format("MM/DD(dd)");
                        return <li>
                            <div className={"number"}>{item.kp_cancel_id}</div>
                            <div className={"date"}> {stringDate}</div>
                            <div className={"timetable"}> {item.timetable}</div>
                            <div className={"justified_left teacher"}>{item.teacher_name}</div>
                            <div className={"justified_left name"}>{item.class_name}</div>
                            <div className={"justified_left department"}>{item.department}</div>
                        </li>
                    })}
                </ul>
                */}
            </div>
        </>
    );
}
