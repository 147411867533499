
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import {applyMiddleware, combineReducers, createStore} from "redux";
import { Provider } from 'react-redux'
import AuthMain from "./Auth/AuthMain";
import * as Reducers from "./flex/Reducers";
import {connection} from "./flex/middleware";


const rootReducer = combineReducers({
    ...Reducers
});
const store = createStore(rootReducer,
    applyMiddleware(
        connection
    )
);

process.env.NODE_ENV !== "development" && (console.log = () => {});

ReactDOM.render(
    <Provider  store={store}>
        <AuthMain />
    </Provider>, document.getElementById('root'));
