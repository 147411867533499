import React, {useEffect, useState} from 'react';
import moment from "moment";
import * as Helper from "../../constants/Helper";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        width: "100vw"
    },
    notFoundContent: {
        height: "calc(100vh - 100px)",
        width : "100vw",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        color: "white"
    }
});

export default function SignageNoContent(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.notFoundContent} >
                {Helper.generateTenantKey().key === "oau"
                    ?
                    null:
                    <h2>お知らせはありません</h2>
                }
            </div>
        </>
    );
}
