import axios from "axios";



const apiAccess = axios.create({
    baseURL : "/"
});

apiAccess.interceptors.request.use((config) => {
    config.headers = {'Content-Type':'application/json'};
    config.timeout = 30000;
    config.responseType = 'json';
    config.baseURL = process.env.REACT_APP_API_HOST;
    console.log(config.url);
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

export { apiAccess };