import {Scrollbars} from 'react-custom-scrollbars';
import React, {Component} from "react";
import IntervalTimer from "react-interval-timer";
import PropTypes from 'prop-types';
import Signage from "./Signage";

export default class SignageScrollbarComponent extends Component {
    constructor(props) {
        super(props);
        this.scrollbars = React.createRef();
        this.state = {
            isScrolling: false,
            waitStartScrolling: true,
            waitEndScrolling: false,
            waitNoScrolling: false,
            count: props.count,
            scrollbars: this.scrollbars,
            settingParam: props.settingParam
        }
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(props, state) {
        if (props.count !== state.count) {
            state.scrollbars.current.view.scroll({
                top: 0
            })
            return {
                isScrolling: false,
                waitStartScrolling: true,
                waitEndScrolling: false,
                waitNoScrolling: false,
                count: props.count,
            }
        }
        if (props.settingParam !== state.settingParam) {
            return {
                settingParam: props.settingParam
            }
        }
        return null;
    }

    scrollTop = () => {
        this.scrollbars.current.view.scroll({
            top: 0
        })
    };

    render() {
        const {settingParam} = this.props;
        return (
            <>
                <Scrollbars
                    ref={this.scrollbars}>
                    {this.props.children}
                </Scrollbars>
                <IntervalTimer
                    timeout={settingParam.hasOwnProperty("SCROLL_SPEED") ? Number(settingParam.SCROLL_SPEED) : 15}
                    callback={() => {
                        if (this.scrollbars.current.getValues().scrollHeight - this.scrollbars.current.getValues().clientHeight <= this.scrollbars.current.getValues().scrollTop) {
                            this.setState({
                                isScrolling: false,
                                waitStartScrolling: false,
                                waitEndScrolling: true
                            })
                        } else {
                            const currentTop = this.scrollbars.current.getValues().scrollTop;
                            this.scrollbars.current.view.scroll({
                                top: currentTop + 1
                            })
                        }
                    }}
                    enabled={this.state.isScrolling}
                    repeat={true}
                />
                {this.state.waitStartScrolling ?
                    <IntervalTimer
                        timeout={settingParam.hasOwnProperty("SCROLL_START_TIME") ? Number(settingParam.SCROLL_START_TIME) : 5000}
                        callback={() => {
                            if (this.scrollbars.current.getValues().scrollHeight - this.scrollbars.current.getValues().clientHeight <= this.scrollbars.current.getValues().scrollTop) {
                                this.setState({
                                    waitNoScrolling: true,
                                    waitStartScrolling: false
                                })
                            } else {
                                this.setState({
                                    isScrolling: true,
                                    waitStartScrolling: false
                                })
                            }
                        }}
                        enabled={this.state.waitStartScrolling}
                        repeat={false}
                    />
                    :
                    null
                }
                {this.state.waitEndScrolling ?
                    <IntervalTimer
                        timeout={settingParam.hasOwnProperty("SCROLL_END_TIME") ? Number(settingParam.SCROLL_END_TIME) : 5000}
                        callback={() => {
                            this.setState({
                                isScrolling: false,
                                waitStartScrolling: false,
                                waitEndScrolling: false,
                                waitNoScrolling: false
                            })
                            this.props.handleScrollEnd();
                        }}
                        enabled={this.state.waitEndScrolling}
                        repeat={false}
                    />
                    :
                    null
                }
                {this.state.waitNoScrolling ?
                    <IntervalTimer
                        timeout={settingParam.hasOwnProperty("NO_SCROLL_END_TIME") ? Number(settingParam.NO_SCROLL_END_TIME) : 15000}
                        callback={() => {
                            this.setState({
                                isScrolling: false,
                                waitStartScrolling: false,
                                waitEndScrolling: false
                            })
                            this.props.handleScrollEnd();
                        }}
                        enabled={this.state.waitNoScrolling}
                        repeat={false}
                    />
                    :
                    null
                }
            </>
        );
    };
}

SignageScrollbarComponent.propTypes = {
    handleScrollEnd: PropTypes.func.isRequired
};

SignageScrollbarComponent.defaultProps = {
    handleScrollEnd: () => {
        console.error("スクロールの終了時のハンドラが未設定")
    }
};