
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_SUB_DIRECTORY = process.env.REACT_APP_API_SUB_DIRECTORY;
export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const API_LOGIN =`/${API_SUB_DIRECTORY}/${API_VERSION}/wall/login`;
export const API_LOGOUT =`/${API_SUB_DIRECTORY}/${API_VERSION}/wall/logout`;

export const API_CURRENT = `/${API_SUB_DIRECTORY}/${API_VERSION}/wall/current`;
//export const API_GET_ALL_CATEGORY =`/${API_SUB_DIRECTORY}/${API_VERSION}/wall/get-all-category`;

export const API_GET_ALL_CATEGORY = `/api/get-all-category`;
export const API_GET_TOPICS = `/api/get-available-topics`;

export const API_KYUKO = `/kpapi/get-kp-cancels`;