import * as Actions from "./Actions";
import {combineActions, handleAction, handleActions} from "redux-actions";

const defaultState = ({
    payload: {},
    meta: {
        fetch: false,
        status: ""
    }
});

const defaultReducer = {
    next: (state, action) => {
        console.log({...action});
        return {...action}
    },
    throw: (state, action) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE
            }
        }))
    }
};



export const loginConnection = handleAction(
    Actions.http.connection.authentication.login,
    defaultReducer,
    defaultState
);
export const logoutConnection = handleAction(
    Actions.http.connection.authentication.logout,
    defaultReducer,
    defaultState
);


export const kyukoConnection = handleAction(
    Actions.http.connection.signage.kyuko,
    defaultReducer,
    defaultState
);
export const categoryConnection = handleAction(
    Actions.http.connection.signage.category,
    defaultReducer,
    defaultState
);
export const topicsConnection = handleAction(
    Actions.http.connection.signage.topics,
    defaultReducer,
    defaultState
);


const defaultAuthState = ({
    payload: {
        is_checked: true,
        ia_authenticated: false
    }
});
const defaultArrayState = ({
    payload: []
});
const defaultObjectState = ({
    payload: {
    }
});
const defaultSettingsObjectState=({
    payload: {
        SCROLL_START_TIME: "5000",
        SCROLL_END_TIME: "5000",
        SCROLL_SPEED: "15",
        NO_SCROLL_END_TIME: "15000",
        NO_TOPIC_END_TIME: "20000"
    }
})


export const session = handleAction(
    Actions.data.session,
    (state, action) => {
        return action
    },
    defaultAuthState
);
export const kyuko = handleAction(Actions.data.kyuko,
    (state, action) => {
        return action
    },
    defaultArrayState
);
export const category = handleAction(Actions.data.category,
        (state, action) => {
            return action
        },
        defaultArrayState
);
export const topics = handleAction(Actions.data.topics,
    (state, action) => {
        return action
    },
    defaultObjectState
);
export const settings = handleAction(
    Actions.data.settings,
    (state, action) => {
        return action
    },
    defaultSettingsObjectState
);


export const incrementReducer = handleAction(
    Actions.increment,
    (state, action) => ({
        counter: state.counter + action.payload.amount
    }),
    defaultState
);