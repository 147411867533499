import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/styles";
import {animated, useTransition} from "react-spring";
import SignageScrollbarComponent from "./SignageScrollbarComponent";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import * as Actions from "../../flex/Actions";
import * as Helper from "../../constants/Helper";
import {array} from "prop-types";
import SignageNoContent from "./SignageNoContent";
import {CSSTransition, Transition} from "react-transition-group";
import SignageLoading from "./SignageLoading";
import IntervalTimer from "react-interval-timer";

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        width: "100vw"
    },
    menu: {
        position: "fixed",
        maxWidth: "24vw",
        width: "24vw",
        height: "calc(100vh - 100px)",
        background: "#13315b",
    },
    menuGutter: {
        height: "20px",
        width: "100%",
        background: "#13315b",
    },
    menuUl: {
        height: "100%",
        width: "100%",
        margin: "0",
        padding: "0",
        listStyleType: "none",
        display: "flex"
    },
    menuLi: {
        background: "#13315b",
        fontSize: "1.6rem",
        color: "white",
        position: "absolute",
        padding: "0 0 0 20px",
        width: "calc(100% - 20px)"
    },
    menuContentWrapper: {
        height: "100%",
        width: "calc(100% - 30px)",
        paddingLeft: 20,
        paddingRight: 10,
        display: "flex",
        alignItems: "center",
    },
    menuContentWrapperFirst: {
        background: "white",
        borderRadius: "5px 0 0 5px",
        paddingLeft: 20,
        color: "#13315b"
    },
    menuContentWrapperSecond: {
        background: "#13315b",
        borderRadius: "0 20px 0 0",
    },
    menuOverlapWrapper: {
        position: "absolute",
        background: "#13315b",
        top: 0,
        left: 0,
        height: 119,
        width: "100%"
    },
    arrowWrapper: {
        zIndex: "9000000",
        background: "white",
        height: 24,
        width: 24
    },
    arrow: {
        position: "absolute",
        right: "0",
        top: "calc(50% - 12px)",
        left: "91%",
        width: "24px",
        height: "24px",
        marginLeft: "-12px",
        borderLeft: "2px solid #13315b",
        borderBottom: "2px solid #13315b",
        transform: "rotate(-135deg)",
        boxSizing: "border-box",
        animation: "$sdb 2s infinite",
        opacity: 0,
        zIndex: 999999999
    },
    arrow0: {
        animationDelay: 0
    },
    arrow1: {
        left: "93%",
        animationDelay: 0
    },
    arrow2: {
        left: "95%",
        animationDelay: 0
    },
    "@keyframes sdb": {
        "0%": {opacity: 0},
        "50%": {opacity: 1},
        "100%": {opacity: 0},
    },
    content: {
        paddingLeft: "24vw",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingRight: "20px",
        width: "calc(76vw - 20px)",
        maxWidth: "76vw",
        background: "#13315b",
        height: "calc(100vh - 140px)",
    },
    topicTitleWrapper: {
        marginTop: "20px",
        padding: "0 20px",
        background: "#13315b",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        borderRadius: 5
    },
    topicTitle: {
        fontSize: "2.0rem",
        color: "white"
    },
    topicSubTitle: {
        color: "white",
        textAlign: "right"
    },
    topicBodyWrapper: {
        padding: "0 20px"
    },
    scrollWrapper: {
        background: "white",
        height: "100%",
        padding: "0 10px 0 20px ",
        borderRadius: "0 5px 5px 5px",
    },
    contentGutter: {
        position: "absolute",
        height: "20px",
        width: "76vw",
        background: "#13315b",
    }
});


export default function SignageBody(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const classes = useStyles();
    let {categoryNum} = useParams();
    const categoryConnection = useSelector(state => state.categoryConnection);
    const topicsConnection = useSelector(state => state.topicsConnection);

    const topics = useSelector(state => state.topics);
    const category = useSelector(state => state.category);
    const query = Helper.useQuery(useLocation().search);
    const settings = useSelector(state => state.settings);

    const [count, setCount] = useState(0);
    const [topicsArray, setTopicsArray] = useState([]);
    const [topicsMenuArray, setTopicsMenuArray] = useState([]);
    const [content, setContent] = useState(null);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [settingParam, setSettingParam] = useState({});

    useEffect(() => {
        let arrayCategory = query.get("loop") ? query.get("loop").split(',') : [];
        setCategories(arrayCategory);
    }, [query.get("loop")]);


    useEffect(() => {
        setLoading(categoryConnection.meta.fetch || topicsConnection.meta.fetch)
    }, [categoryConnection, topicsConnection])

    useEffect(() => {
        setTopicsArray(topics.payload.hasOwnProperty(categoryNum) ? topics.payload[categoryNum] : []);
        setTopicsMenuArray(topics.payload.hasOwnProperty(categoryNum) ? topics.payload[categoryNum] : []);
    }, [topics, categoryNum])

    useEffect(() => {
        setContent(topicsArray[count % topicsArray.length])
    }, [topicsArray, topicsMenuArray])

    useEffect(() => {
        sortArray();
        setContent(topicsArray[count % topicsArray.length]);
    }, [count]);


    useEffect(() => {
        setSettingParam(settings.payload)
    }, [settings])

    const changeTopic = () => {
        if ((count + 1) % topicsArray.length === 0) {
            if (categories.length === 0) {
                window.location.reload();
                dispatch(Actions.http.connection.signage.category());
                setCount(count + 1);
            } else {
                const currentCategoryIndex = categories.findIndex((element) => element === categoryNum);
                const nextCategoryIndex = currentCategoryIndex + 1 === categories.length ? 0 : currentCategoryIndex + 1;
                history.push(`/signage/${categories[nextCategoryIndex]}/${window.location.search}`);
                if(nextCategoryIndex === 0){
                    window.location.reload();
                }
                setCount(0);
            }
        } else {
            if (topicsArray.length === 0) {
                if (categories.length === 0) {
                    dispatch(Actions.http.connection.signage.category());
                    setCount(0);
                } else {
                    const currentCategoryIndex = categories.findIndex((element) => element === categoryNum);
                    const nextCategoryIndex = currentCategoryIndex + 1 === categories.length ? 0 : currentCategoryIndex + 1;
                    history.push(`/signage/${categories[nextCategoryIndex]}/${window.location.search}`);
                    if(nextCategoryIndex === 0){
                        window.location.reload();
                    }
                    setCount(0);
                }
            } else {
                setCount(count + 1);
            }
        }
    };

    const sortArray = () => {
        if (topicsArray.length !== 0) {
            const tempArray = topicsMenuArray.concat();
            const firstObject = tempArray[0];
            tempArray.shift();
            tempArray.push(firstObject);
            setTopicsMenuArray(tempArray);
        }
        ;
    };

    let height = 0;
    const heightSize = 120;
    const transition = useTransition(
        topicsMenuArray.map((data) => {
            return {...data, y: (height += heightSize) - heightSize, height: 120}
        }),
        d => d.TOPIC_ID,
        {
            from: {height: 0, opacity: 0},
            leave: {height: 0, opacity: 0},
            enter: ({y, height}) => ({y, height, opacity: 1}),
            update: ({y, height}) => ({y, height})
        }
    );

    return (
        <CSSTransition
            in={!loading}
            timeout={2000}
            classNames={"loading"}
        >
            <div className={classes.wrapper}>
                {
                    loading ?
                        <SignageLoading/>
                        :
                        topicsArray.length === 0
                            ?
                            <>
                                <SignageNoContent/>
                                <IntervalTimer
                                    //timeout={settingParam.hasOwnProperty("NO_TOPIC_END_TIME") ? Number(settingParam.NO_TOPIC_END_TIME) : 15000 }
                                    timeout={10000}
                                    callback={() => {
                                        //sliceArray()
                                        changeTopic();
                                    }}
                                    enabled={true}
                                    repeat={false}
                                />
                            </>
                            :
                            <>
                                <div className={classes.menu}>
                                    <div className={classes.menuGutter}/>
                                    <ul className={classes.menuUl}>
                                        {transition.map(({item, props: {y, ...rest}, key}, index) => {
                                            const stringDate = moment(item.execute_date).format("MM/DD(dd)");
                                            return (
                                                <animated.li
                                                    className={`${classes.menuLi}`}
                                                    key={key}
                                                    style={{
                                                        zIndex: topicsArray.length - index,
                                                        transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
                                                        ...rest
                                                    }}>
                                                    <div className={`${classes.menuContentWrapper}
                                            ${index === 0 ? classes.menuContentWrapperFirst : null}`}>
                                    <span>
                                        {item.TOPIC_TITLE}
                                    </span>
                                                    </div>
                                                </animated.li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                {content ?
                                    <div className={classes.content}>
                                        <div className={classes.scrollWrapper}>
                                            <SignageScrollbarComponent handleScrollEnd={changeTopic} count={count}
                                                                       settingParam={settingParam}>
                                                <>
                                                    <div className={classes.topicTitleWrapper}>
                                                        <div className={classes.topicTitle}>{content.TOPIC_TITLE}</div>
                                                        <div className={classes.topicSubTitle}>
                                                            {`配信元：${content.TOPIC_CREATOR_NAME}  配信日： ${moment(content.TOPIC_CREATED).format("YYYY年MM月DD日 hh時mm分")}`}
                                                        </div>
                                                    </div>
                                                    <div className={classes.topicBodyWrapper}>
                                                        {content.TOPIC_PDF_ATTACH !== "1" ?
                                                            <FroalaEditorView
                                                                model={content.TOPIC_BODY}
                                                            />
                                                            :
                                                            (() => {
                                                                const pdfMath = Math.floor(window.innerWidth * 0.76 - 40 - 40);
                                                                let imageSerial = content.MEDIAS.map((value, index) => {
                                                                    const tempUrl = content.API_ENDPOINT + 'api/getimage(dw=' + pdfMath + ',ds=s)/' + content.API_SERVICE_NAME + '/' + value.MEDIA_MD5
                                                                    return <img key={index} src={tempUrl}/>
                                                                });
                                                                return (
                                                                    <div className="topic_full_image">
                                                                        {imageSerial}
                                                                    </div>
                                                                )
                                                            })()
                                                        }
                                                    </div>
                                                </>
                                            </SignageScrollbarComponent>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </>
                }


            </div>
        </CSSTransition>
    );
}
