import React, {useEffect, useState} from 'react';
import * as Actions from "../../../flex/Actions";
import {useDispatch} from "react-redux";
import CancelsEffectComponent from "../CancelsEffectComponent";
import MobileCancelsHeader from "./MobileCancelsHeader";
import MobileCancelsBody from "./MobileCancelsBody";
import {useParams} from "react-router-dom";
import * as Helper from "../../../constants/Helper";

export default function Cancels() {
    const dispatch = useDispatch();

    dispatch(Actions.http.connection.signage.kyuko())
    let {id} = useParams();

    return (
        <>
            <MobileCancelsHeader title={Helper.generateCancelCategory(id)}/>
            <CancelsEffectComponent/>
            <div className={"mobile-content-wrapper"}>
                <MobileCancelsBody/>
            </div>
        </>
    );
}
