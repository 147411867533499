
export const generateTenantKey = (host) => {
    switch(window.location.host){
        case "ks-cancels.toyo.ist-reserve.space":
        case "ks-signage.toyo.ist-reserve.space":
            //東洋
            return {key:"toyo" , name:"東洋大学"};
        case "ks-cancels.oau.ist-reserve.space":
        case "ks-signage.oau.ist-reserve.space":
            //大阪青山
            return {key:"oau" , name:"大阪青山"};
        default:
        case "localhost:3000":
            //それ以外の場合はdefaultとして処理
            return {key:"default" , name: "KnowledgeSense"};
    }
};


export const useQuery = (locationSearch) => {
    return new URLSearchParams(locationSearch);
}



export const generateCampusName = (id) => {

    /*
        1: 白山休講情報
        2: 白山休講情報（文学部第1部）
        3: 白山休講情報（経済学部第1部)
        4: 白山休講情報（経営学部第1部)
        5: 白山休講情報（法学部第1部)
        6: 白山休講情報（社会学部第1部)
        7: 白山休講情報（国際地位学科・グローバル・イノベーション学科)
        8: 白山休講情報（国際観光学科）
        9: 朝霞休講情報
        10:川越休講情報
        11:板倉休講情報
     */
    switch(id) {
        case "1":
        case 1:
        case 2:
        case "2":
        case 3:
        case "3":
        case 4:
        case "4":
        case 5:
        case "5":
        case 6:
        case "6":
        case 7:
        case "7":
        case 8:
        case "8":
            return "白山";
        case 9:
        case "9":
            return "朝霞";
        case 10:
        case "10":
            return "川越";
        case 11:
        case "11":
            return "板倉";
        default:
            return "";
    }
}


export const generateCancelCategory = (id) => {
    /*
        1: 白山休講情報
        2: 白山休講情報（文学部第1部）
        3: 白山休講情報（経済学部第1部)
        4: 白山休講情報（経営学部第1部)
        5: 白山休講情報（法学部第1部)
        6: 白山休講情報（社会学部第1部)
        7: 白山休講情報（国際地位学科・グローバル・イノベーション学科)
        8: 白山休講情報（国際観光学科）
        9: 朝霞休講情報
        10:川越休講情報
        11:板倉休講情報
     */
    switch(id){
        case "1":
        case 1:
            return "白山休講情報";
        case 2:
        case "2":
            return "白山休講情報（文学部第1部）";
        case 3:
        case "3":
            return "白山休講情報（経済学部第1部)";
        case 4:
        case "4":
            return "白山休講情報（経営学部第1部)";
        case 5:
        case "5":
            return "白山休講情報（法学部第1部)";
        case 6:
        case "6":
            return "白山休講情報（社会学部第1部)";
        case 7:
        case "7":
            return "白山休講情報（国際地位学科・グローバル・イノベーション学科)";
        case 8:
        case "8":
            return "白山休講情報（国際観光学科）";
        case 9:
        case "9":
            return "朝霞休講情報";
        case 10:
        case "10":
            return "川越休講情報";
        case 11:
        case "11":
            return "板倉休講情報";
        default:
            return "休講情報";
    }
}