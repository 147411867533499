import React, {useEffect, useState} from 'react';
import * as Actions from "../../flex/Actions";
import {useDispatch, useSelector} from "react-redux";
import {Switch, useLocation, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import {useTransition, animated} from 'react-spring'
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';
import IntervalTimer from 'react-interval-timer';
import moment from "moment";
import "moment/locale/ja";
import {Circle} from 'rc-progress';
import * as Helper from "../../constants/Helper";
import CancelsNoContent from "./CancelsNoContent";

moment.locale('ja')

const useStyles = makeStyles({
    table: {
        height: "100%",
        width: "100%"
    },
    progressWrapper: {
        position: "absolute",
        height: "calc(100vh - 100px)",
        width: "100%"
    },
    progress: {
        position: "absolute",
        right: 20,
        bottom: 20,
        height: 100,
        width: 100,
        zIndex: 111111
    },
    progressText: {
        position: "absolute",
        right: 20,
        bottom: 20,
        height: 100,
        width: 100,
        zIndex: 111111,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    molecule: {
        fontSize: 40
    },
    detominator: {
        fontSize: 20
    }
});


const generateArray = (id, returnObject) => {
    //id（現段階のパラメータ)
    /*
        1: 白山休講情報
        2: 白山休講情報（文学部第1部）
        3: 白山休講情報（経済学部第1部)
        4: 白山休講情報（経営学部第1部)
        5: 白山休講情報（法学部第1部)
        6: 白山休講情報（社会学部第1部)
        7: 白山休講情報（国際地位学科・グローバル・イノベーション学科)
        8: 白山休講情報（国際観光学科）
        9: 朝霞休講情報
        10:川越休講情報
        11:板倉休講情報
     */
    console.log(returnObject);
    if (id) {
        return returnObject.hasOwnProperty(id) ? returnObject[id] : []
    } else {
        let generatedArray = returnObject.hasOwnProperty(1) ? returnObject[1].concat() : [];
        let generatedArray2 = returnObject.hasOwnProperty(2) ? returnObject[2].concat() : [];
        let generatedArray3 = returnObject.hasOwnProperty(3) ? returnObject[3].concat() : [];
        let generatedArray4 = returnObject.hasOwnProperty(4) ? returnObject[4].concat() : [];
        let generatedArray5 = returnObject.hasOwnProperty(5) ? returnObject[5].concat() : [];
        let generatedArray6 = returnObject.hasOwnProperty(6) ? returnObject[6].concat() : [];
        let generatedArray7 = returnObject.hasOwnProperty(7) ? returnObject[7].concat() : [];
        let generatedArray8 = returnObject.hasOwnProperty(8) ? returnObject[8].concat() : [];
        let generatedArray9 = returnObject.hasOwnProperty(9) ? returnObject[9].concat() : [];
        let generatedArray10 = returnObject.hasOwnProperty(10) ? returnObject[10].concat() : [];
        let generatedArray11 = returnObject.hasOwnProperty(11) ? returnObject[11].concat() : [];
        generatedArray = generatedArray.concat(generatedArray2, generatedArray3, generatedArray4, generatedArray5, generatedArray6, generatedArray7, generatedArray8, generatedArray9, generatedArray10, generatedArray11);

        return generatedArray;
    }
};


const needScroll = (array) => {
    return array.length > 10
};


export default function CancelsBody() {
    const classes = useStyles();
    const kyuko = useSelector(state => state.kyuko);
    const dispatch = useDispatch();
    let {id} = useParams();
    const [count, setCount] = useState(0);
    const [kyukoArray, setKyukoArray] = useState([]);
    const [isOdd, setCurrentNth] = useState(true);

    useEffect(() => {
        setKyukoArray(generateArray(id, kyuko.payload))
    }, [kyuko]);


    useEffect(() => {
        sortArray();
    }, [count]);


    let height = 0;
    const heightSize = 90;
    const transition = useTransition(
        kyukoArray.map((data) => {
            console.log(height)
            return {...data, y: (height += heightSize) - heightSize, height: heightSize}
        }),
        d => d.kp_cancel_id,
        {
            from: {height: 0, opacity: 0},
            leave: {height: 0, opacity: 0},
            enter: ({y, height}) => ({y, height, opacity: 1}),
            update: ({y, height}) => ({y, height})
        }
    );

    const sortArray = () => {
        if (kyukoArray.length !== 0) {
            //サイズ固定で 10個以上存在する場合のみsortさせる
            if(needScroll(kyukoArray)){
                const firstObject = kyukoArray[0];
                kyukoArray.shift();
                kyukoArray.push(firstObject);
                setKyukoArray(kyukoArray);
                setCurrentNth(!isOdd);
                //スクロールが必要な場合なので、2週程度まわったタイミングでリロード
                if(count % (kyukoArray.length * 3) === 0 ){
                    dispatch(Actions.http.connection.signage.kyuko())
                }
            }else{
                //スクロールが不要な場合なので、リストの数の10倍程度のタイミングでリロード
                if(count % (kyukoArray.length * 10) === 0 ){
                    dispatch(Actions.http.connection.signage.kyuko())
                }
            }
        }else{
            //120秒程度でリロード
            if(count % 120  === 0){
                dispatch(Actions.http.connection.signage.kyuko())
            }
        };
    };



    const sliceArray = () => {
        if(needScroll(generateArray(id, kyuko.payload))){
            if(kyukoArray.length <= 10){
                setKyukoArray(generateArray(id, kyuko.payload));
            }else{
                const nextArray = kyukoArray.slice(10,kyukoArray.length);
                setKyukoArray(nextArray);
            }
        }
    };



    const calcPercent = () => {
        return ((count % kyukoArray.length + 1) / kyukoArray.length) * 100;
    };

    return (
        <>
            <div id={"signage"}>
                {needScroll(kyukoArray) ?
                    <div className={classes.progressWrapper}>
                        <div className={classes.progress}>
                            <Circle percent={calcPercent()} strokeWidth="4" strokeColor="#39a3dc"/>
                        </div>
                        <div className={classes.progressText}>
                            <p>
                                <span className={classes.molecule}>{count % kyukoArray.length + 1}</span><span
                                className={classes.detominator}>/{kyukoArray.length}</span>
                            </p>
                        </div>
                    </div>
                    :
                    null
                }
                <div className={`signage-list-header`}>
                    <div className={"number cell"}>No</div>
                    <div className={"date cell"}>休講日</div>
                    <div className={"timetable cell"}>時間割</div>
                    <div className={"teacher cell"}>教員</div>
                    <div className={"name cell"}>科目名</div>
                    <div className={"department cell"}>キャンパス/学部</div>
                </div>
                {kyukoArray.length !== 0 ?

                    <ul className={`signage-list ${isOdd ? "odd" : "even"}`}>
                        {transition.map(({item, props: {y, ...rest}, key}, index) => {
                            const stringDate = moment(item.execute_date).format("MM/DD(dd)");
                            return (
                                <animated.li
                                    key={key}
                                    style={{
                                        zIndex: kyukoArray.length - index,
                                        transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
                                        ...rest
                                    }}>
                                    <div className={"number"}>{item.kp_cancel_id}</div>
                                    <div className={"date"}> {stringDate}</div>
                                    <div className={"timetable"}> {item.timetable}</div>
                                    <div className={"justified_left teacher"}>{item.teacher_name}</div>
                                    <div className={"justified_left name"}>{item.class_name}</div>
                                    <div
                                        className={"justified_left department"}>{Helper.generateCampusName(item.kp_cancel_category_id)}/{item.department}</div>
                                </animated.li>
                            )
                        })}
                    </ul>
                    :
                    <CancelsNoContent />
                }
            </div>
            <IntervalTimer
                timeout={1200}
                callback={() => {
                    //sliceArray()
                    setCount(count + 1);
                }}
                enabled={true}
                repeat={true}
            />
        </>
    );
}