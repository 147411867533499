import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';
import {
    useHistory, useLocation, useParams,
} from "react-router-dom";
import * as Actions from "../../flex/Actions";
import * as Helper from "../../constants/Helper";
import {array} from "prop-types";




export default function SignageEffectComponent() {

    const categoryConnection = useSelector(state => state.categoryConnection)
    const topicsConnection = useSelector(state => state.topicsConnection)
    const dispatch = useDispatch();
    let {categoryNum} = useParams();
    const query = Helper.useQuery(useLocation().search);


    useEffect(()=>{

        dispatch(Actions.http.connection.signage.category());
    },[categoryNum])




    useEffect(() => {
        if(categoryConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.data.category(categoryConnection.payload.data));

            let currentCategories = [];
            if(categoryNum){

                if(categoryNum.match(/,/)){
                    //カンマが含まれているので区切り文字
                    const arrayCategory = categoryNum.split(',');
                    window.location.href = `/signage/${arrayCategory[0]}?loop=${categoryNum}`;
                }else{
                    currentCategories.push(categoryNum);
                }

            }else{
                categoryConnection.payload.data.some((value,index) => {
                    currentCategories.push(value.CATEGORY_ID);
                });
                //signageのカテゴリナンバーがない場合は、末尾のカテゴリ指定queryが存在すればその1行目、なければカテゴリリストの一番上を表示します。
                const arrayCategory = query.get("loop") ? query.get("loop").split(',') : [];
                if(arrayCategory.length === 0){
                    window.location.href = `/signage/${categoryConnection.payload.data[0].CATEGORY_ID}`;
                }else{
                    window.location.href = `/signage/${arrayCategory[0]}${window.location.search}`;
                }
            }
            const postData = {CATEGORIES: currentCategories};
            dispatch(Actions.http.connection.signage.topics(postData));
        }

        if(categoryConnection.meta.status === Actions.statusEnum.FAILURE || categoryConnection.meta.status === Actions.statusEnum.ERROR){
            //dispatch(Actions.data.session(false));
        }
    }, [categoryConnection]);


    useEffect(()=>{
        if(topicsConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.data.topics(topicsConnection.payload.data));
            dispatch(Actions.data.settings(topicsConnection.payload.data.SETTINGS));
        }
        if(topicsConnection.meta.status === Actions.statusEnum.FAILURE || topicsConnection.meta.status === Actions.statusEnum.ERROR){
            //dispatch(Actions.data.session(false));
        }
    }, [topicsConnection]);


    return null
}