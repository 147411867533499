import React, {useEffect, useState} from 'react';
import * as Actions from "../../flex/Actions";
import {useDispatch, useSelector} from "react-redux";
import {Switch, useParams} from "react-router-dom";
import SignageHeader from "./SignageHeader";
import SignageEffectComponent from "./SignageEffectComponent";
import SignageBody from "./SignageBody";

export default function Signage() {

    return (
        <>
            <SignageHeader/>
            <SignageEffectComponent/>
            <div className={"content-wrapper"}>
                <SignageBody />
            </div>
        </>
    );
}
