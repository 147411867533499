import React, {useEffect, useState} from 'react';
import * as Actions from "../../flex/Actions";
import {useDispatch} from "react-redux";
import {Switch, useParams} from "react-router-dom";
import AuthEffectComponent from "../../Auth/AuthEffectComponent";
import CancelsHeader from "./CancelsHeader";
import CancelsEffectComponent from "./CancelsEffectComponent";
import CancelsBody from "./CancelsBody";
import * as Helper from "../../constants/Helper";

export default function Cancels() {
    const dispatch = useDispatch();
    dispatch(Actions.http.connection.signage.kyuko())

    let {id} = useParams();
    return (
        <>
            <CancelsHeader title={Helper.generateCancelCategory(id)}/>
            <CancelsEffectComponent/>
            <div className={"content-wrapper"}>
                <CancelsBody/>
            </div>
        </>
    );
}
