import React, {useEffect, useState} from 'react';
import moment from "moment";
import {makeStyles} from "@material-ui/styles";


const useStyles = makeStyles({
    mobileHeaderWrapper: {
        height: "50px",
        width: "100%",
        background: "black"
    },
    mobileHeaderTitle: {
        paddingLeft: 16,
        display: "flex",
        color: "white",
        height: "50px",
        alignContent: "center",
        alignItems: "center"

    },
    mobileHeaderAction: {}
});

export default function MobileCancelsHeader(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.mobileHeaderWrapper}>
                <div className={classes.mobileHeaderTitle}>
                    {props.title}
                </div>
                <div className={classes.mobileHeaderAction}>

                </div>
            </div>
        </>
    );
}
