import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        width: "100vw"
    },
    notFoundContent: {
        height: "calc(100vh - (50px))",
        width : "100vw",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        color: "black"
    }
});

export default function MobileCancelsNoContent(props) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.notFoundContent} >
                <h5>現在公開されている休講情報はありません</h5>
            </div>
        </>
    );
}
