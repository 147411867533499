import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dispatch } from 'redux';
import {
    useHistory,
} from "react-router-dom";
import * as Actions from "../../flex/Actions";




export default function CancelsEffectComponent() {

    const kyukoConnection = useSelector(state => state.kyukoConnection)
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(kyukoConnection)
        if(kyukoConnection.meta.status === Actions.statusEnum.SUCCESS){
            dispatch(Actions.data.kyuko(kyukoConnection.payload.data));
        }
        if(kyukoConnection.meta.status === Actions.statusEnum.FAILURE || kyukoConnection.meta.status === Actions.statusEnum.ERROR){
            //dispatch(Actions.data.session(false));
        }
    }, [kyukoConnection]);

    return null

}