import {createActions, createAction, handleActions, combineActions} from 'redux-actions'
import * as endpoints from "../apis/endpoints";


export const statusEnum = {
    REQUEST: "REQUEST",
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
};

export const http = createActions({
    CONNECTION: {
        AUTHENTICATION: {
            LOGIN: [
                (data) => ({
                    method: "post",
                    api: endpoints.API_LOGIN,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            LOGOUT: [
                () => ({
                    method: "get",
                    api: endpoints.API_LOGOUT
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CURRENT: [
                () => ({
                    method: "get",
                    api: endpoints.API_CURRENT,
                    query: {
                        target: "admin"
                    }
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        SIGNAGE: {
            KYUKO: [
                (data) => ({
                    method: "post",
                    api: endpoints.API_KYUKO,
                    data: {
                        "kp_cancel_category_ids":[1,2,3,4,5,6,7,8,9,10,11]
                    }
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CATEGORY :[
                () => ({
                    method: "get",
                    api: endpoints.API_GET_ALL_CATEGORY
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            TOPICS: [
                (data) => ({
                    method: "post",
                    api: endpoints.API_GET_TOPICS,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        }
    }
});

export const data = createActions({
    SESSION: (is_authenticated) => ({
        is_checked : true,
        is_authenticated
    }),
    KYUKO: data => (data),
    CATEGORY: data => (data),
    TOPICS: data => (data),
    SETTINGS: data => (data)
});

export const increment = createAction('INCREMENT');